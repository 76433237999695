/**
 * @description 土地藏品回收首页
 * @returns {route: /collectionRecoveryLand/home}
*/

import React, {useEffect, useState, useRef} from 'react';
import qs from 'query-string';
import CountDown from '../components/countDown';
import NFTModal from '@/components/NFTModal';
import ActivityRules from './activityRules';
import RecyclingType from './recyclingType';
import NumberScroll from '@/components/NumberScroll';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { hideLoading, showLoading } from '@/components/loading';
import { Toast } from 'antd-mobile';
import Service from '../service';
import './index.scss';

const jackpotNum = '2000000';

const Home = () => {
  const navigate = useNavigate();
  const { activityCode } = qs.parse(window.location.search);
  if(activityCode) {
    Service.activityCode = activityCode;
  }
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState({});
  const pageDom = useRef(null);

  useEffect(() => {
    (async () => {
      await getData()
      onDidFocus();
    })();
  }, []) 

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getData()
      onDidFocus();
    });
  }

  const onClickRules = () => {
    const ModalInstance = NFTModal.show({
      content: <ActivityRules activityCode={Service.activityCode} onClose={() => ModalInstance?.close()} />,
      getContainer: pageDom.current,
    });
  }

  const onRecyclingType = (type) => {
    if (!userInfo?.ID) {
      Toast.show('请先实名认证');
      return;
    }
    const ModalInstance = NFTModal.show({
      content: <RecyclingType onClose={() => ModalInstance?.close()} type={type} activityCode={Service.activityCode} navigate={navigate}/>,
      getContainer: pageDom.current,
    });
  }

  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }

  const queryRecyclingRecord = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/collectionRecoveryLand/recoveryRecord?activityCode=${Service.activityCode}`});
    } else {
      navigate(`/collectionRecoveryLand/recoveryRecord?activityCode=${Service.activityCode}`);
    }
  }

  const getData = async () => {
    try {
      showLoading();
      const [userInfo, data, config] = await Promise.all([
        Service.getUserCertificateStatus(),
        Service.getActivityData(), 
        Service.getActivityConfig(),
      ])
      hideLoading();
      setUserInfo(userInfo);
      setData({...data, ...config});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const { systemDate, configInfo, remainCount } = data || {};
  const currentTime = new Date(systemDate?.replace(/-/g, "/")).getTime();
  const endT = new Date(configInfo?.recEndTime?.replace(/-/g, "/")).getTime();
  const isEnd = currentTime >= endT;
  const isDisabled = currentTime >= endT || remainCount <= 0;
  
  return (
    <div className="land-collection-recovery-home" ref={pageDom}>
      <div className="back" onClick={onBack}></div>
      <div className="activity_rules" onClick={onClickRules}>活动规则</div>
      {!userInfo?.ID && <div className="real_name" onClick={() => window.JsBridge.navigate('Verify')}>实名认证</div>}
      <div className="game_out">
        <div className="game_container">
          <div className="time_record">
            <div className="lottery_time"><CountDown sysTime={data.systemDate} endTime={data?.configInfo?.recEndTime}/></div>
            <div className="lottery_record" onClick={queryRecyclingRecord}>回收记录&gt;</div>
          </div>
          <div className="Jackpot_box">
            <NumberScroll endValue={jackpotNum} textStyle={{textColor:'#D10073'}}/>
            {/* {jackpotNum.split('').map((item, index) => {
              return <span key={index}>{item}</span>
            })} */}
          </div>
          <div className="recycling_times">
            <div>可回收：<span>{data?.ableRecCount ?? '-'}</span>个</div>
            <div>剩余回收次数：<span>{data?.remainCount ?? '-'}</span>次</div>
          </div>
          <div className="game_btn_box">
            <div className={classNames('left_btn', { 'disabled': isDisabled })} onClick={() => onRecyclingType(1)}>
              {isEnd ? <>
                <span></span>
                <span>活动结束</span></>: <>
                <span>多人</span>
                <span>平分对应奖池</span>
              </>}
            </div>
            <div className={classNames('right_btn', { 'disabled': isDisabled })} onClick={() => onRecyclingType(2)}>
              {isEnd ? <>
                <span></span>
                <span>活动结束</span></>: <>
                <span>随机抽取规定数量</span>
                <span>瓜分对应奖池</span>
              </>}
            </div>
          </div>
        </div>
        <div className="game_bottom"></div>
      </div>
      <div className="logo_box"></div>
    </div>
  )
}

export default Home;