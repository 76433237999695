import React, { useState, useRef, useEffect } from 'react'
import styles from './modal.module.scss'
import { Input, Toast, Tabs } from 'antd-mobile'
import close from '@/assets/img/landfi/close-circle-fill.png'
import lock from '@/assets/img/landfi/pwd-lock.png'
import {isEmptyString} from "@/utils/publicFun"
import clickThrottle from "@/utils/clickThrottle"
import { isIOSNew } from '@/utils/publicFun'
import MyModal from './modal'
import { showLoading, hideLoading } from '@/components/loading'
import ListView from "@/components/AntListView"
import dayjs from 'dayjs'
import { getUserCollectionsSetting } from '@/api/projectManager_self'
import { transferRecord, gemstoreTransfer, getGemstoreCount, gemstoreFeesConfig } from '@/api/guland'

export const GemTransferView = ({ onClose, container, refreshData }) => {
  const [transferNum, setTransferNum] = useState('')
  const [transferAddress, setTransferAddress] = useState('')
  const [gemstoreInfo, setGemstoreInfo] = useState({})

  useEffect(() => {
    window.JsBridgeNew.addKeyboardEvent()
    gemstoreInit()
  }, [])

  const gemstoreInit = async () => {
    try {
      showLoading()
      const [conuntInfo, configInfo] = await Promise.all([queryGemstoreCounts(), queryFeesConfig()])
      hideLoading()
      setGemstoreInfo({...conuntInfo, ...configInfo})
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const queryGemstoreCounts = async () => {
    const res = await getGemstoreCount()
    if (res?.code !== '000000') throw res?.msg
    return res?.data || {}
  }

  const queryFeesConfig = async () => {
    const res = await gemstoreFeesConfig()
    if (res?.code !== '000000') throw res?.msg
    return res?.data || {}
  }

  const confirmTransfer = async () => {
    if (!clickThrottle()) return
    if (!transferNum) {
      Toast.show('请输入转赠数量')
      return
    }
    if (!transferAddress) {
      Toast.show('请输入转赠地址')
      return
    }
    //有无设置过支付密码
    try {
      showLoading();
      const res = await getUserCollectionsSetting({})
      hideLoading();
      const {code, msg, data} = res
      if (code !== '000000') throw msg
      const {isHasPassword} = data
      if (isHasPassword) {
        pwdInput()
      } else {
        pwdSetTip()
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const pwdInput = () => {
    const ModalInstance = MyModal.show({
      content: <InputPassword 
        onClose={() => ModalInstance?.close()} 
        onRefresh={refreshGemstoreData}
        count={transferNum}
        address={transferAddress}
        fees={gemstoreInfo.fees || 0}
      />,
      getContainer: container,
    })
  }

  const pwdSetTip = () => {
    const ModalInstance = MyModal.show({
      content: <SetPasswordTip onClose={() => ModalInstance?.close()}/>,
      getContainer: container,
    })
  }

  const refreshGemstoreData = () => {
    setTransferNum('')
    setTransferAddress('')
    gemstoreInit()
    refreshData && refreshData()
  }

  const onTransferRecord = () => {
    const ModalInstance = MyModal.show({
      content: <TransferRecord onClose={() => ModalInstance?.close()}/>,
      getContainer: container,
      maskStyle: {background: `rgba(0, 0, 0, 0)`}
    })
  }

  const maxCount = gemstoreInfo?.gemstonesCount-gemstoreInfo.fees > 0 ? gemstoreInfo.gemstonesCount-gemstoreInfo.fees: 0

  return <div className={styles.gem_transfer}>
    <img className={styles.close_img} src={close} alt="" onClick={() => onClose()}/>
    <div className={styles.gem_header}>
      <span>宝石转赠</span>
      <span onClick={onTransferRecord}>转赠记录&gt;&gt;</span>
    </div>
    <div className={styles.gemstore_counts}>当前拥有{gemstoreInfo.gemstonesCount || 0}宝石</div>
    <div className={styles.input_box}>
      <div className={styles.label}>转赠数量</div>
      <Input 
        className={styles.input}
        placeholder='请输入转赠数量'
        type="number"
        value={transferNum}
        max={maxCount}
        min={1}
        onChange={(e) => {
          if (/^\+?[1-9][0-9]*$/.test(e)) {
            if (e > maxCount) {
              Toast.show(`最多可转赠${maxCount}宝石`)
              setTransferNum(maxCount)
              return
            }
            setTransferNum(e)
          } else {
            setTransferNum('')
          }
        }}
      />
    </div>
    {gemstoreInfo.fees ? <div className={styles.commission_tip}>
      含{gemstoreInfo.fees}宝石手续费，最多可转赠{maxCount}宝石
    </div>: <div className={styles.tip_empty}></div>}
    <div className={`${styles.input_box} ${styles.second_input}`}>
      <div className={styles.label}>区块链地址</div>
      <Input 
        className={styles.input}
        placeholder='请输入转赠地址'
        value={transferAddress}
        onChange={(e) => {
          setTransferAddress(e)
        }}
      />
    </div>
    <div className={styles.transfer_btn} onClick={confirmTransfer}>确认转赠</div>
  </div>
}

export const SetPasswordTip = ({ onClose }) => {
  return <div className={styles.set_pwd_tip}>
    <img src={lock} alt="" />
    <p>您未设置转赠密码<br />请先设置密码</p>
    <div className={styles.footer_btn}>
      <div className={`${styles.btn_box} ${styles.cancel}`} onClick={() => onClose()}>取消</div>
      <div 
        className={`${styles.btn_box} ${styles.goset}`} 
        onClick={() => {
          onClose()
          window.JsBridgeNew.hiddenStatusBar(false)
          window.JsBridgeNew.screenRotate(true)
          window.JsBridge.push('Web', {uri: `${window.location.origin}/pwdSetting`})
        }}
      >去设置</div>
    </div>
  </div>
}

const InputPassword = ({ onClose, onRefresh, count, address, fees }) => {
  const [pwd, setPwd] = useState('')

  const onChange = (e) => {
    let newVal = e.replace(/[^\d]/g, '');
    newVal = newVal.length > 6 ? newVal.substr(0, 6): newVal
    setPwd(newVal)
  }

  const onConfirm = async () => {
    if (!clickThrottle()) return
    if (pwd.length !== 6) return
    showLoading()
    try {
      const res = await gemstoreTransfer({
        count: count,
        userWalletAddress: address,
        password: pwd,
        fees,
      })
      hideLoading()
      if (res?.code !== '000000') throw res?.msg
      onClose()
      onRefresh()
      Toast.show('转赠成功')
    } catch (error) {
      Toast.show(error)
      hideLoading()
    }
  }

  const wrapString = (path) => {
    const prefix = path.startsWith('content://') ? 'RNFetchBlob-content://' : 'RNFetchBlob-file://'
    return prefix + path
  };

  const inVivoIdentification = async (livenessResult) => { //活体识别
    let realName = ''
    let userInfo0 = await window.JsBridge.getCurrentUserInfo()
    if (userInfo0 && userInfo0.clientId) { //登录了
      let userInfo1 = await window.JsBridge.getDetailCurrentUserInfo()
      if (!isEmptyString(userInfo1.ID)) { //实名过了
        realName = userInfo1.realName
      }
    }
    let param = {
      file: {
        name: 'motionLivenessResult',
        filename: 'motionLivenessResult',
        data: wrapString(livenessResult.livenessFile.replace('file:///', '')),
        type: 'multipart/form-data'
      },
      supplier: '1',
      name: realName,
    };
    window.JsBridgeNew.jKTServicesRequest({
      moduleName: 'UserLogin',
      code: '101162',
      method: 'postFile',
      params: param
    }).then((res) => {
      if (res.status > 0) {
        window.JsBridgeNew.hiddenStatusBar(false)
        window.JsBridgeNew.screenRotate(true)
        window.JsBridge.push("Web", {uri: `${window.location.origin}/pwdSetting?type=${1}`})
      }
    })
  }

  const faceShiBie = async () => {
    if (!clickThrottle()) return;
    if (isIOSNew()) {
      window.JsBridgeNew.hiddenStatusBar(false)
      window.JsBridgeNew.screenRotate(true)
      setTimeout(async () => {
        const res = await window.JsBridge.startLiveness()
        window.JsBridgeNew.hiddenStatusBar(true)
        window.JsBridgeNew.screenRotate(false)
        if (res.livenessId !== '-1') {
          inVivoIdentification(res)
        } else {
          Toast.show(res.msg)
        }
      }, 300);
    } else {
      const res = await window.JsBridge.startLiveness()
      if (res.livenessId !== '-1') {
        inVivoIdentification(res)
      } else {
        Toast.show(res.msg)
      }
    }
  }

  return <div className={styles.input_pwd}>
    <img className={styles.close} src={close} onClick={() => onClose()} alt="" />
    <h1>请输入密码</h1>
    <div className={styles.pwd_box}>
      <div className={styles.pwd_list}>
        {Array(6).fill('').map((item, index) => <div key={index} className={styles.pwd_item}>{pwd[index] ? '*': ''}</div>)}
      </div>
      <Input value={pwd} autoFocus className={styles.pwd_input}  pattern="[0-9]*" inputMode="numeric"  onChange={onChange} type='text'  maxLength={6}/>
    </div>
    <div className={styles.forget_pwd} onClick={faceShiBie}>忘记密码？使用人脸识别认证&gt;</div>
    <div className={styles.footer_btn}>
      <div className={`${styles.btn_box} ${styles.cancel}`} onClick={() => onClose()}>取消</div>
      <div 
        className={`${styles.btn_box} ${styles.goset}`} 
        onClick={onConfirm}
      >确认转赠</div>
    </div>
  </div>
}

const TransferRecord = ({onClose}) => {
  const [currentTab, setCurrentTab] = useState('0')
  const [recordsData, setRecordsData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(10);
  const domRef = useRef(null)

  useEffect(() => {
    getRecordsList()
  }, [currentTab])

  const getRecordsList = async (isRefresh=true, loading=true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        type: Number(currentTab) + 1,
      }
      const res = await transferRecord(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setRecordsData(res?.data?.records || []);
      } else {
        setRecordsData([...recordsData, ...(res?.data?.records || [])])
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.records?.length >= pageSize.current)
    } catch (error) {
      console.log(error)
      loading && hideLoading()
      Toast.show(error)
    }
  }

  const ListItem = (item) => {
    const { createdAt, count, raddress, address, fees } = item || {}
    return <div className={styles.list_item}>
      <div className={styles.item_top}>
        <div>
          <span className={styles.time_span}>转赠时间：</span>
          <span>{createdAt ? dayjs(createdAt).format('YYYY.MM.DD HH:mm:ss'): ''}</span>
        </div>
        <div>
          <span>转赠个数：</span>
          <span>{count}</span>
        </div>
        {currentTab === '0' ? <div>
          <span>手续费：</span>
          <span>{fees}</span>
        </div>: null}
      </div>
      <div>
        <span className={styles.time_span}>{currentTab === '0' ? '接收人': '转赠人'}：</span>
        <span>{currentTab === '0' ? raddress: address}</span>
      </div>
    </div>
  }
  
  return <div className={styles.transfer_record}>
    <img className={styles.close} onClick={onClose} src={close} alt="" />
    <h1>转赠记录</h1>
    <Tabs className={styles.tabs} activeKey={currentTab} onChange={setCurrentTab}>
      <Tabs.Tab 
        key='0'
        title={<div className={`${styles.tab_title} ${currentTab === '0' ? styles.tab_select: ''}`}>转出</div>}
      ></Tabs.Tab>
      <Tabs.Tab 
        key='1'
        title={<div className={`${styles.tab_title} ${currentTab === '1' ? styles.tab_select: ''}`}>转入</div>}
      ></Tabs.Tab>
    </Tabs>
    <div className={styles.tabs_content} ref={domRef}>
      <ListView
        dataSource={recordsData}
        hasMore={hasMore}
        style={{paddingTop: 5}}
        renderItem={ListItem}
        onEndReached={async () => {
          await getRecordsList(false, false);
        }}
        isLoad={isLoad}
        customStyle={{
          imgStyle: {width: 120, height: 120},
          textStyle: {color: '#999', fontSize: 12},
        }}
      />
    </div>
  </div>
}